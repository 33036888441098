* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.App {
  width: 100%;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: 1;
}

.App-logo {
  min-height: 100px;
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: fadeIn 0.5s ease-out;
  } */
}

.App-header {
  width: 100%;
}
/* Typography  */
h1,
h2 {
  margin: 1rem;
}
p {
  max-width: 600px;
  font-size: 1.25rem;
  line-height: 175%;
  margin: 0.5rem auto;
}
h2 svg {
  vertical-align: bottom;
}
.App-link {
  color: #61dafb;
}
.background-logo {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 20vmin;
}
.choose-store {
  /* background-image: url(./assets/clover-point-sunset-1078-blur.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  /* font-size: calc(10px + 1vmin); */
  height: 100%;
  min-height: 725px;
  max-height: 100%;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: hidden;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(46, 46, 46);
  /* border: 1rem double #222222; */
  background-color: rgba(250, 250, 250, 1);
}
section {
  /* width: 100%; */
  padding: 0.5rem;
}

.store-regions {
  margin: 2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.store-regions button {
  opacity: 0.5;
  height: 100%;
  margin: 0.4rem;
  background: transparent;
  border: none;
  border: 2px solid gray;
  padding: 1rem;
  transition: all 0.2s ease;
}
.store-regions button img {
  width: 100%;
}
.store-regions div:hover button {
  animation: pulse 0.7s cubic-bezier(0.5, 0.6, 0.75, 0.9) alternate-reverse infinite;
  /* -webkit-animation: pulse 0.4s ease alternate-reverse infinite; */
}
.store-regions div:hover button {
  opacity: 1;
  cursor: pointer;
  background: rgb(0, 99, 247);
  border: 2px solid rgb(0, 99, 247);
}
.store-regions div:hover p {
  color: rgb(0, 99, 247);
}
.store-regions button.active {
  border: 2px solid dodgerblue;
  background: transparent;
  opacity: 1;
}
@keyframes pulse {
  0% {
    transform: scale(1.015);
  }
  100% {
    transform: scale(1);
  }
}
.region {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.region-name {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spacer {
  width: 100%;
  height: 2rem;
  display: block;
}

/* Error Page */
.error-page {
  min-height: 100vh;
  width: auto;
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
}

/* Animations */
.animation-fade-in {
  /* animation: fadeIn 0.3s ease-out; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.75);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/** Buttons **/

button {
  padding: 0.75rem 1.2rem;
  background: white;
  border: 1px solid grey;
  /* box-shadow: 0 2px 0 0 black; */
  font-size: 1rem;
}
button:hover {
  cursor: pointer;
}
button:active {
  color: black;
  background: grey;
}
button svg {
  vertical-align: bottom;
}

/***********************************/
/*********** MOBILE ****************/
/***********************************/
@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .App-logo {
    min-height: unset;
    height: 15vmin;
    pointer-events: none;
  }
  .region-name p {
    line-height: 1;
    font-size: 16px;
    white-space: nowrap;
  }
}

/***********************************/
/******* SMALL VIEW HEIGHT *********/
/***********************************/
@media screen and (max-height: 1120px) and (min-width: 1251px) {
  .choose-store {
    flex-direction: row;
    height: 100%;
  }
  /** The left section with Header name and description **/
  section:nth-of-type(1) {
    flex-basis: 65%;
  }
  /** The right section with the country buttons **/
  section:nth-of-type(2) {
    height: 100%;
  }
  .store-regions {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 100%;
  }
  .region {
    display: flex;
    height: 30%;
    margin: 0.5rem;
    flex-direction: row;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .store-regions .region p {
    flex-shrink: 1 !important;
    display: block;
    text-align: right;
    margin: 0.55rem;
  }
  .store-regions .region-name {
    justify-content: flex-end;
  }
  .store-regions button {
    min-height: 0;
    min-width: 0;
    width: auto;
    height: auto;
    height: 100%;
    flex-shrink: 1;
  }
  .store-regions button img {
    height: 100%;
    width: auto;
  }
}
